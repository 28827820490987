body {
  font-family: "Bricolage Grotesque", sans-serif;
  font-family: "Inter", sans-serif;
}

section {
  height: 100vh;
}

.mobile-section {
  height: calc(100vh - 0px);
}

.btn-mobile {
  position: relative;
  left: 160px;
  font-size: 24px;
  text-decoration: none;
  transform: translate(-50%, -50%);
  color: #0f0911;
  border-radius: 2px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  transition: 0.2s;
  overflow: hidden;
  background: #0cff6d;
}
.btn-mobile:before {
  z-index: -1;
  content: "";
  position: absolute;
  left: -100%;
  top: 0%;
  background: #0f0911;
  width: 100%;
  height: 100%;
  transition: 0.2s;
}
.btn-mobile:after {
  z-index: -1;
  content: "";
  position: absolute;
  left: -100%;
  top: 0%;
  background: #0cff6d;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  transition-delay: 0.2s;
  color: #0cff6d;
}
.btn-mobile:hover:before {
  left: 0;
}
.btn-mobile:hover:after {
  left: 0;
}
.btn-mobile:hover .text {
  animation: colorChange 2s infinite;
}

@keyframes colorChange {
  0% {
    color: #0f0911; /* Set your original text color here */
  }
  50% {
    color: #f1cdfe; /* Set the color you want during the transition here */
  }
  100% {
    color: #0f0911; /* Revert to the original color */
  }
}
.btn {
  font-size: 24px;
  text-decoration: none;
  transform: translate(-50%, -50%);
  color: #0f0911;
  border-radius: 2px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  transition: 0.2s;
  overflow: hidden;
  background: #0cff6d;
}
.btn:before {
  z-index: -1;
  content: "";
  position: absolute;
  left: -100%;
  top: 0%;
  background: #0f0911;
  width: 100%;
  height: 100%;
  transition: 0.2s;
}
.btn:after {
  z-index: -1;
  content: "";
  position: absolute;
  left: -100%;
  top: 0%;
  background: #0cff6d;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  transition-delay: 0.2s;
  color: #0cff6d;
}
.btn:hover:before {
  left: 0;
}
.btn:hover:after {
  left: 0;
}
.btn:hover .text {
  animation: colorChange 0.3s;
}

.btn:hover ::before {
  top: 0;
}
.btn:hover ::after {
  top: 0;
}
a:hover {
  color: #0cff6d;
}

.redir:hover .base {
  display: none;
}

.redir:hover .arrow {
  display: block;
}

.text {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  color: #0f0911;
  transition: all 0.1s ease-out;
  display: block;
}
